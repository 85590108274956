<template>
  <esmp-select
    ref="databaseSelect"
    v-model="column.value"
    :placeholder="column.label || column.name"
    filterable
    clearable
    :remote-method="debouncedSearch"
    :show-search-results="isShowSearchResults"
    @on-query-change="setQuery"
    @on-select="setSelectedVal"
    @on-clear="clearVal"
    :error-message="errorMessage"
  >
    <esmp-select-option
      v-for="(item, optionIndex) in column.values"
      :key="`option-${item.value}-${optionIndex}`"
      :value="item.value"
    >
      {{ item.value }}
    </esmp-select-option>
  </esmp-select>
</template>

<script>
import debounce from 'lodash/debounce';
import { SEARCH_DELAY } from '@/constants/search';

export default {
  name: 'DatabaseSearch',
  props: {
    index: {
      type: Number,
      required: true,
    },
    column: {
      type: Object,
      default: null,
    },
    isClearing: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isShowSearchResults() {
      return this.column?.values?.length > 0;
    },
  },
  data() {
    return {
      isLoading: false,
      query: '',
    };
  },
  watch: {
    isClearing(val) {
      if (val) {
        this.query = '';
        this.$refs.databaseSelect.$children[0].query = this.query;
      }
    },
  },
  methods: {
    setQuery(value) {
      this.query = value;
    },
    setSelectedVal(value) {
      this.setColumnValue(value);
    },
    clearVal() {
      this.setColumnValue();
    },
    search() {
      if (!this.column.value) {
        const column = {
          ...this.column,
          searchQuery: this.query,
        };
        this.$emit('update-search-column', column, this.index);
      } else if (this.query && this.column?.value?.trim() !== this.query?.trim()) {
        const column = {
          ...this.column,
          value: null,
          searchQuery: this.query,
        };
        this.$emit('update-search-column', column, this.index);
      }
    },
    setColumnValue(value) {
      this.$emit('set-column-value', value, this.index);
    },
  },
  created() {
    this.debouncedSearch = debounce(this.search, SEARCH_DELAY);
  },
  updated() {
    this.$refs.databaseSelect.$children[0].query = this.query;
  },
};
</script>
